import React, {useState, useEffect, useRef, useDebugValue} from "react";
import useGlobal from "../store";
import axios from "axios";

export default function Form(props) {
  const [globalState, globalActions] = useGlobal();
  
  // Refs
  const fileInputRef = useRef(null); // The hidden file input
  const previewRef = useRef(null); // The element that previews the user's uploaded photo
  const sectionRef = useRef(null); // The section element that contains the form

  // User Workflow States
  const [view, setView] = useState("start"); // Which view to show
  const [pledge, setPledge] = useState({}); // Holds the form data after submit
  const [error, setError] = useState(""); // HTML for error messages
  const [uploadedPhoto, setUploadedPhoto] = useState(false); // Toggle to true when the user has uploaded a photo.

  // Form Input States
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [cellPhone, setCellPhone] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [memberNumber, setMemberNumber] = useState("");
  const [localNumber, setLocalNumber] = useState("");
  const [councilNumber, setCouncilNumber] = useState("");
  const [photo, setPhoto] = useState("");
  const [sticky, setSticky] = useState(false);

  // Form Label States
  const defaultLabels = {
    firstName: (<label className="share-label" htmlFor="first_name">First Name <span className="required">*</span></label>),
    lastName: (<label className="share-label" htmlFor="last_name">Last Name <span className="required">*</span></label>),
    email: (<label className="share-label" htmlFor="email">Email Address <span className="required">*</span></label>),
    cellPhone: (<label className="share-label" htmlFor="cell_phone">Mobile Number</label>),
    city: (<label className="share-label" htmlFor="city">City</label>),
    state: (<label className="share-label" htmlFor="state">State <span className="required">*</span></label>),
    memberNumber: (<label className="share-label" htmlFor="member-number">Member Number</label>),
    localNumber: (<label className="share-label" htmlFor="local-number">Local Number</label>),
    councilNumber: (<label className="share-label" htmlFor="council-number">Council Number</label>),
    zip: (<label className="share-label" htmlFor="zip">Zip Code</label>),
    photo: (<label className="share-label" htmlFor="photo">Your Photo <span className="required">*</span></label>)
  };

  const errorLabels = {
    firstName: (<label htmlFor="first_name" className="share-label required">First Name *</label>),
    lastName: (<label htmlFor="last_name" className="share-label required">Last Name *</label>),
    email: (<label htmlFor="email" className="share-label required">Email Address *</label>),
    state: (<label htmlFor="state" className="share-label required">State *</label>),
    photo: (<label htmlFor="photo" className="share-label required">Your Photo *</label>),
    zip: (<label htmlFor="zip" className="share-label required">Zip Code</label>),
    cellPhone: (<label htmlFor="cell_phone" className="share-label required">Mobile Number</label>)
  };
  
  const [firstNameLabel, setFirstNameLabel] = useState(defaultLabels.firstName);
  const [lastNameLabel, setLastNameLabel] = useState(defaultLabels.lastName);
  const [emailLabel, setEmailLabel] = useState(defaultLabels.email);
  const [cellPhoneLabel, setCellPhoneLabel] = useState(defaultLabels.cellPhone);
  const [cityLabel, setCityLabel] = useState(defaultLabels.city);
  const [stateLabel, setStateLabel] = useState(defaultLabels.state);
  const [memberNumberLabel, setMemberNumberLabell] = useState(defaultLabels.memberNumber);
  const [councilNumberLabel, setCouncilNumberLabell] = useState(defaultLabels.councilNumber);
  const [localNumberLabel, setLocalNumberLabell] = useState(defaultLabels.localNumber);
  const [zipLabel, setZipLabel] = useState(defaultLabels.zip);
  const [photoLabel, setPhotoLabel] = useState(defaultLabels.photo);

  /**
   * Checks to see if each required form field has a value. If not, its label is swapped out.
   * @return {boolean} true if all fields validate; false if any fields are invalid
   */
  const validate = function() {
    const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    const zipRegex = /\d{5}(?:[-\s]\d{4})?/;
    const phoneRegex = /\(?\d{3}\)?-? *\d{3}-? *-?\d{4}/;

    let valid = true;

    if(firstName.length === 0) {
      setFirstNameLabel(errorLabels.firstName);
      valid = false;
    } else {
      setFirstNameLabel(defaultLabels.firstName);
    }

    if(lastName.length === 0) {
      setLastNameLabel(errorLabels.lastName);
      valid = false;
    } else {
      setLastNameLabel(defaultLabels.lastName);
    }

    if(email.match(emailRegex) === null) {
      setEmailLabel(errorLabels.email);
      valid = false;
    } else {
      setEmailLabel(defaultLabels.email);
    }

    // if(state.length === 0) {
    //   setStateLabel(errorLabels.state);
    //   valid = false;
    // } else {
    //   setStateLabel(defaultLabels.state);
    // }

    if(document.getElementById("pledge_photo_custom").checked || document.getElementById("pledge_photo_avatar1").checked || document.getElementById("pledge_photo_avatar2").checked) {
      setPhotoLabel(defaultLabels.photo);
    } else {
      setPhotoLabel(errorLabels.photo);
      valid = false;
    }

    if(zip.length > 0) {
      if(zip.match(zipRegex) === null) {
        setZipLabel(errorLabels.zip);
        valid = false;
      } else {
        setZipLabel(defaultLabels.zip);
      }
    }

    if(cellPhone.length > 0) {
      if(cellPhone.match(phoneRegex) === null) {
        setCellPhoneLabel(errorLabels.cellPhone);
        valid = false;
      } else {
        setCellPhoneLabel(defaultLabels.cellPhone);
      }
    }

    return valid;
  };

  /**
   * Either create or update a pledge.
   * @param {object} e - The form submit event.
   * @param {string} nextView - Which view should be displayed after the save is successful?
   * @param {object} formData - The new data for the pledge. Will default to whatever is in the form.
   * @param {boolean} runValidations - Should the data be validated before saving?
   */
  const savePledge = function(e, nextView, formData = null, runValidations = true) {
    
    e.preventDefault();

    const save = runValidations ? validate() : true;
    let url, method;

    let params = {};
    const search = window.location.search.substring(1);

    if (search.length > 0) {
      params = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
      document.getElementById("pledge-source").value = params.source;
    }

    if(save) {
      if(formData === null) {
        formData = new FormData(document.getElementById("pledge-form"));
      }

      if(pledge.id) {
        url = `/pledges/${pledge.id}.json`;
        method = "PATCH";
      } else {
        url = `/pledges.json`;
        method = "POST";
      }

      setView("loading");
      axios({
        url: url,
        method: method,
        data: formData,
        headers: {
          "X-CSRF-TOKEN": document.querySelector("[name=csrf-token]").content,
          "Content-Type": "multipart/form-data"
        }
      }).then(function(response){
        if(response.data.pledge) {
          setPledge(response.data.pledge);
          setView(nextView);
          setError("");
          { document.getElementById("app").scrollIntoView() }
        } else {
          setError(<div className="error">There was a problem. Please try again.</div>);
        }
      });
    }
  };

  /**
   * Takes a user's photo and reads it as a data url, and places it into the preview image tag.
   * @param {object} e - The onChange event from the file input, which holds the photo data.
   */
  const handlePhotoChange = function(e) {
    const reader = new FileReader();
    const file = e.target.files[0];

    reader.addEventListener("load", (() => previewRef.current.style.backgroundImage = `url(${reader.result})`), false);
    reader.readAsDataURL(file);

    setUploadedPhoto(true);
  };

  /**
   * Remove the user's photo by toggling the uploadedPhoto boolean and removing the check from the radio button.
   */
  const deletePhoto = function() {
    setSticky(false);
    setUploadedPhoto(false);
    document.getElementById("pledge_photo_custom").checked = false;
  };

  /**
   * Removes any checked avatar when the user uploads their own photo.
   */
  const removeAvatars = function() {
    setSticky(true);
    document.getElementById("pledge_photo_avatar1").checked = false;
    document.getElementById("pledge_photo_avatar2").checked = false;
  };

  /**
   * Renders the HTML for the current view.
   */
  const renderView = function() {
    switch(view) {
      case "form":
        return renderForm();
        break;
      case "loading":
        return renderLoading();
        break;
      case "thanks":
        return renderThanks();
        break;
    }
  };

  /**
   * Treat a click on section.form like a click on the .share-button
   */
  const handleSectionClick = function() {
    if(view === "start") {
      setView("form");
    }
  };

  /**
   * Handle data when the view changes
   */
  useEffect(() => {
    if(view !== "start") {
      globalActions.setFormExpanded(true);
      document.body.classList.add('expanded-form');
    } else {
      globalActions.setFormExpanded(false);
      document.body.classList.remove('expanded-form');
    }

    if(view === "thanks") {
      // AFSCME doesn't want to preview the pledge after submittal
      // uncomment this line if they change their mind
      //globalActions.setPledges([pledge], true);
    }
  }, [view]);

  const renderButton = function() {
    const classes = globalState.formExpanded ? "share-button expanded" : "share-button";

    return(<button onClick={() => globalState.formExpanded ? setView("start") : setView("form")} className={classes}>
      <div className="share-button-aspect">
        <div className="share-button-positioner">
          Add A <em className="share-button-break">photo</em>
        </div>
      </div>
      <div className="share-button-close">
        <svg className="share-button-close-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 640">
          <path d="M459.136 475.168c-15.008 15.008-39.328 15.008-54.304 0l-84.832-96.96-84.832 96.928c-15.008 15.008-39.328 15.008-54.304 0-15.008-15.008-15.008-39.328 0-54.304l88.256-100.8-88.288-100.864c-15.008-15.008-15.008-39.296 0-54.304s39.296-15.008 54.304 0l84.864 96.992 84.832-96.992c15.008-15.008 39.296-15.008 54.304 0s15.008 39.328 0 54.304l-88.256 100.864 88.256 100.8c15.008 15.008 15.008 39.328 0 54.336z"></path>
        </svg>
      </div>
    </button>);
  };

  const renderPhotoUpload = function() {
    if(uploadedPhoto) {
      return (<React.Fragment>
        <div className="delete share-radio-avatar-uploaded-delete" onClick={() => deletePhoto()}>
          <svg className="share-radio-avatar-uploaded-delete-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 640">
            <path d="M459.136 475.168c-15.008 15.008-39.328 15.008-54.304 0l-84.832-96.96-84.832 96.928c-15.008 15.008-39.328 15.008-54.304 0-15.008-15.008-15.008-39.328 0-54.304l88.256-100.8-88.288-100.864c-15.008-15.008-15.008-39.296 0-54.304s39.296-15.008 54.304 0l84.864 96.992 84.832-96.992c15.008-15.008 39.296-15.008 54.304 0s15.008 39.328 0 54.304l-88.256 100.864 88.256 100.8c15.008 15.008 15.008 39.328 0 54.336z"></path>
          </svg>
        </div>
        <label className="share-radio-label" htmlFor="pledge_photo_custom">
          <div className="share-radio-avatar" ref={previewRef}></div>
          <div className="share-radio-check">
            <svg className="share-radio-check-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 400"><path d="M432,0L192,240L80,128L0,208l192,192L512,80L432,0z"></path></svg>
          </div>
        </label>
      </React.Fragment>);
    } else {
      return (<React.Fragment>
        <label className="share-radio-label" htmlFor="pledge_photo_custom">
          <div className="share-radio-avatar">
            <div className="share-radio-avatar-upload">
              <svg className="share-radio-avatar-upload-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 480 416"><path d="M240 168c19.83 0 36.79 7.04 50.88 21.13S312 220.17 312 240s-7.04 36.79-21.13 50.88S259.83 312 240 312s-36.79-7.04-50.88-21.13S168 259.83 168 240s7.04-36.79 21.13-50.88S220.17 168 240 168zM416 64c17.67 0 32.75 6.25 45.25 18.75S480 110.33 480 128v224c0 17.67-6.25 32.75-18.75 45.25S433.67 416 416 416H64c-17.67 0-32.75-6.25-45.25-18.75S0 369.67 0 352V128c0-17.67 6.25-32.75 18.75-45.25S46.33 64 64 64h56l12.75-34c3.17-8.17 8.96-15.21 17.38-21.13S167.17 0 176 0h128c8.83 0 17.46 2.96 25.88 8.88s14.2 12.95 17.37 21.12L360 64h56zM240 352c30.83 0 57.21-10.96 79.13-32.88S352 270.83 352 240s-10.96-57.21-32.88-79.13S270.83 128 240 128s-57.21 10.96-79.13 32.88S128 209.17 128 240s10.96 57.21 32.88 79.13S209.17 352 240 352z"></path></svg>
              <span className="share-radio-avatar-upload-label">Add Image</span>
            </div>
          </div>
          <div className="share-radio-check">
            <svg className="share-radio-check-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 400"><path d="M432,0L192,240L80,128L0,208l192,192L512,80L432,0z"></path></svg>
          </div>
        </label>
      </React.Fragment>);
    }
  };

  const renderForm = function() {
    return(<form id="pledge-form" className="share-form">
      <input type="hidden" name="pledge[sticky]" value={sticky} />
      <input type="hidden" id="pledge-source" name="pledge[source]" value="" />

      {error}
      <div className="share-fields">
        <div className="share-field">
          {firstNameLabel}
          <input type="text" className="share-input required" id="first_name" name="pledge[first_name]" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
        </div>

        <div className="share-field">
          {lastNameLabel}
          <input type="text" className="share-input required" id="last_name" name="pledge[last_name]" value={lastName} onChange={(e) => setLastName(e.target.value)} />
        </div>
      </div>

      <div className="share-fields">
        <div className="share-field">
          {emailLabel}
          <input type="email" className="share-input required" id="email" name="pledge[email]" value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>

        <div className="share-field">
          {cellPhoneLabel}
          <input type="text" className="share-input" id="cell_phone" name="pledge[cell_phone]" value={cellPhone} onChange={(e) => setCellPhone(e.target.value)} />
        </div>
      </div>

      <div className="share-fields">
        <div className="share-field">
          {zipLabel}
          <input type="text" className="share-input" id="zip" name="pledge[zip]" value={zip} onChange={(e) => setZip(e.target.value)} />
        </div>
      </div>
      
      <div className="share-grouping" style={{display: 'none'}}>

        <h3 className="share-headline">Local Details</h3>

        <div className="share-fields">

          <div className="share-field">
            {memberNumberLabel}
            <input className="share-input" type="text" name="pledge[member_number]" id="member-number"/>
          </div>

          <div className="share-field">
            {localNumberLabel}
            <input className="share-input" type="text" name="pledge[local_number]" id="local-number"/>
          </div>

          <div className="share-field">
            {councilNumberLabel}
            <input className="share-input" type="text" name="pledge[council_number]" id="council-number"/>
          </div>

        </div>
        
      </div>
      
      <div className="share-grouping">
      
        <h3 className="share-headline">Choose Your Photo</h3>

        <div className="share-radios">
          <div className="share-radio" onClick={() => removeAvatars()}>
            <input name="pledge[photo]" type="file" name="pledge[photo]" ref={fileInputRef} onChange={(e) => handlePhotoChange(e)} style={{display: "none"}} accept="image/*" />
            <input className="share-radio-input" id="pledge_photo_custom" type="radio" onClick={() => fileInputRef.current.click()} />
            {renderPhotoUpload()}
          </div>

          <div className="share-radio" onClick={() => deletePhoto()}>
            <input className="share-radio-input" id="pledge_photo_avatar1" name="pledge[pledge_url]" value="https://www.afscme.org/courage/avatar1.jpg" type="radio" />
            <label className="share-radio-label" htmlFor="pledge_photo_avatar1">
              <div className="share-radio-avatar" style={{ backgroundImage:"url(https://www.afscme.org/courage/avatar1.jpg)" }}></div>
              <div className="share-radio-check">
                <svg className="share-radio-check-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 400"><path d="M432,0L192,240L80,128L0,208l192,192L512,80L432,0z"></path></svg>
              </div>
            </label>
          </div>
          
          <div className="share-radio" onClick={() => deletePhoto()}>
            <input className="share-radio-input" id="pledge_photo_avatar2" name="pledge[pledge_url]" value="https://www.afscme.org/courage/avatar2.jpg" type="radio" />
            <label className="share-radio-label" htmlFor="pledge_photo_avatar2">
              <div className="share-radio-avatar" style={{ backgroundImage:"url(https://www.afscme.org/courage/avatar2.jpg)" }}></div>
              <div className="share-radio-check">
                <svg className="share-radio-check-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 400"><path d="M432,0L192,240L80,128L0,208l192,192L512,80L432,0z"></path></svg>
              </div>
            </label>
          </div>
          
        </div>
        
        <div className="share-grouping">
          <div className="share-fields">
            <div className="share-field">
              <label className="share-label" for="describe-photo">Describe your photo:</label>
              <input className="share-input" type="text" name="pledge[describe_photo]" id="describe-photo"/>
            </div>
          </div>
        </div>    

        <div className="share-submit-container">
          <button className="share-submit" onClick={(e) => savePledge(e, "thanks")}>Share</button>
        </div>
        
        <div className="share-disclaimer">*By providing your cell phone number you consent to receive calls 
        (including recorded or autodialed calls, or texts) at that number from AFSCME and its affiliated labor, 
        political and charitable organizations on any subject matter. Your carrier’s rates may apply. 
        You may modify your preferences at: <a target="new" href="https://www.afscme.org/tcpa">www.afscme.org/tcpa</a>.
        </div>
      </div>
    </form>);
  };

  const renderLoading = function() {
    return(<div>
      <h1>Loading. Please Wait.</h1>
    </div>);
  };

  const renderThanks = function() {
    return(<div className="share-post-submit" id="share-post-submit">
      <h3 className="share-headline">Thanks for your submission!</h3>
      <p className="share-description">Thank you for submitting a photo to the Honoring AFSCME Veterans Wall. Photos are not immediately added – please allow 1-2 days for it to appear on the page. Thank you for your incredible dedication and service to our country.</p>
      <div className="share-buttons" role="navigation" aria-label="Share this Site on Social Media">
        <a className="share-buttons-link" href="https://www.facebook.com/sharer/sharer.php?u=https://courage.afscme.org" target="_blank">
          <div className="share-buttons-link-center">
            <svg className="share-buttons-link-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><title>Share on Facebook</title><path d="M304 96h80V0h-80c-61.757 0-112 50.243-112 112v48h-64v96h64v256h96V256h80l16-96h-96v-48c0-8.673 7.327-16 16-16z"></path></svg>
            <div className="share-buttons-link-txt">Share on <strong className="share-buttons-link-txt-network">Facebook</strong></div>
          </div>
        </a>
        <a className="share-buttons-link" href="https://twitter.com/share?text=On Veterans Day, we honor AFSCME members and their family members who have served in the military. Share a photo on the Honoring AFSCME Veterans Wall&url=https://courage.afscme.org&hashtags=VeteransDay" target="_blank">
          <div className="share-buttons-link-center">
            <svg className="share-buttons-link-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><title>Share on Twitter</title><path d="M512 113.2c-18.8 8.4-39.1 14-60.3 16.5 21.7-13 38.3-33.6 46.2-58.1-20.3 12-42.8 20.8-66.7 25.5C412 76.7 384.7 64 354.5 64c-58 0-105 47-105 105 0 8.2.9 16.2 2.7 23.9-87.3-4.4-164.7-46.2-216.5-109.8-9 15.5-14.2 33.6-14.2 52.8 0 36.4 18.5 68.6 46.7 87.4-17.2-.5-33.4-5.3-47.6-13.1v1.3c0 50.9 36.2 93.4 84.3 103-8.8 2.4-18.1 3.7-27.7 3.7-6.8 0-13.3-.7-19.8-1.9 13.4 41.7 52.2 72.1 98.1 73-36 28.2-81.2 45-130.5 45-8.5 0-16.8-.5-25.1-1.5C46.5 462.7 101.7 480 161 480c193.2 0 298.9-160.1 298.9-298.9 0-4.6-.1-9.1-.3-13.6 20.5-14.7 38.3-33.2 52.4-54.3z"></path></svg>
            <div className="share-buttons-link-txt">Share on <strong className="share-buttons-link-txt-network">Twitter</strong></div>
          </div>
        </a>
      </div>
    </div>);
  };
  
  const renderStates = function(){
    return(<select className="share-input required" id="state" name="pledge[state]" value={state} onChange={(e) => setState(e.target.value)}>
			<option>Choose state</option>
			<option value="AL">Alabama</option>
			<option value="AK">Alaska</option>
			<option value="AZ">Arizona</option>
			<option value="AR">Arkansas</option>
			<option value="CA">California</option>
			<option value="CO">Colorado</option>
			<option value="CT">Connecticut</option>
			<option value="DE">Delaware</option>
			<option value="DC">District Of Columbia</option>
			<option value="FL">Florida</option>
			<option value="GA">Georgia</option>
			<option value="HI">Hawaii</option>
			<option value="ID">Idaho</option>
			<option value="IL">Illinois</option>
			<option value="IN">Indiana</option>
			<option value="IA">Iowa</option>
			<option value="KS">Kansas</option>
			<option value="KY">Kentucky</option>
			<option value="LA">Louisiana</option>
			<option value="ME">Maine</option>
			<option value="MD">Maryland</option>
			<option value="MA">Massachusetts</option>
			<option value="MI">Michigan</option>
			<option value="MN">Minnesota</option>
			<option value="MS">Mississippi</option>
			<option value="MO">Missouri</option>
			<option value="MT">Montana</option>
			<option value="NE">Nebraska</option>
			<option value="NV">Nevada</option>
			<option value="NH">New Hampshire</option>
			<option value="NJ">New Jersey</option>
			<option value="NM">New Mexico</option>
			<option value="NY">New York</option>
			<option value="NC">North Carolina</option>
			<option value="ND">North Dakota</option>
			<option value="OH">Ohio</option>
			<option value="OK">Oklahoma</option>
			<option value="OR">Oregon</option>
			<option value="PA">Pennsylvania</option>
			<option value="RI">Rhode Island</option>
			<option value="SC">South Carolina</option>
			<option value="SD">South Dakota</option>
			<option value="TN">Tennessee</option>
			<option value="TX">Texas</option>
			<option value="UT">Utah</option>
			<option value="VT">Vermont</option>
			<option value="VA">Virginia</option>
			<option value="WA">Washington</option>
			<option value="WV">West Virginia</option>
			<option value="WI">Wisconsin</option>
			<option value="WY">Wyoming</option>
		</select>)
  }
  

  const sectionClasses = globalState.formExpanded ? "share expanded" : "share";

  return(<React.Fragment>
    {renderButton()}
    <section className={sectionClasses} ref={sectionRef} onClick={() => handleSectionClick()}>
      {renderView()}
    </section>
  </React.Fragment>);
}
