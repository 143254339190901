import React from "react";
import useGlobal from "../store";
import axios from "axios";

export default function Wall(props) {
  const [globalState, globalActions] = useGlobal();

  const clearSearch = function () {
    axios({
      url: `/?page=${globalState.page}&format=json`,
      method: "GET",
      headers: { "X-CSRF-TOKEN": document.querySelector("[name=csrf-token]").content }
    }).then(function (response) {
      globalActions.setSearching(false);
      globalActions.setSearchTerm("");
      globalActions.setPledges(JSON.parse(response.data), false, true);
      { document.body.classList.remove('search-entered') }
    });
  };

  const renderSearch = function () {
    if (globalState.searching) {
      return (<div className="search-results" id="search-results">
        <h3 className="search-results-headline">There {globalState.pledges.length == 0 && 'are'} {globalState.pledges.length > 1 && 'are'} {globalState.pledges.length == 1 && 'is'} {globalState.pledges.length} post{globalState.pledges.length == 0 && 's'}{globalState.pledges.length > 1 && 's'} for <em>{globalState.searchTerm}</em></h3>
        <button className="search-results-clear" onClick={() => clearSearch()}>Clear</button>
      </div>);
    }
  };

  return (<React.Fragment>
    {renderSearch()}
    <section className="wall">
      <h2 className="wall-headline">AFSCME Veterans</h2>
      <div className="wall-grid">
        {globalState.pledges.map((pledge) => props.pledgeBox(pledge))}
      </div>
    </section>
  </React.Fragment>)
}
